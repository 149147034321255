<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <v-img class="auth-mask-bg" :src="getPhoto"></v-img>
            <!-- triangle bg -->
            <!--<img
    height="362"
    class="auth-mask-bg"
    :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
  />-->
            <!-- tree -->
            <!--<v-img
    height="226"
    width="300"
    class="auth-tree"
    src="@/assets/images/misc/tree-2.png"
  ></v-img>-->
            <!-- 3d character -->
            <!--<div class="d-flex align-center h-full pa-16 pe-0">
    <v-img
      contain
      max-width="100%"
      height="710"
      class="auth-3d-group"
      :src="require(`@/assets/images/3d-characters/illustration-register-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    ></v-img>
  </div>-->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    Create a new user for Epiito Cloud
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text v-if="!errorMessages.registerSucces">
                  <v-form ref="registerForm"
                          @submit.prevent="handleFormSubmit">
                    <v-text-field v-model="username"
                                  outlined
                                  label="Username"
                                  :error-messages="errorMessages.username"
                                  :rules="[validators.required, validators.alphaValidator]"
                                  placeholder="Username"
                                  hide-details="auto"
                                  class="mb-6"></v-text-field>

                    <v-text-field v-model="email"
                                  outlined
                                  :error-messages="errorMessages.email"
                                  :rules="[validators.required, validators.emailValidator]"
                                  label="Email"
                                  placeholder="Email"
                                  hide-details="auto"
                                  class="mb-6"></v-text-field>

                    <v-text-field v-model="password"
                                  outlined
                                  :type="isPasswordVisible ? 'text' : 'password'"
                                  label="Password"
                                  :error-messages="errorMessages.password"
                                  placeholder="Password"
                                  :rules="[validators.required, validators.passwordValidator]"
                                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                                  hide-details="auto"
                                  class="mb-2"
                                  @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                    <v-checkbox hide-details
                                class="mt-0">
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          <span class="me-2">I agree to</span><a href="https://www.epiito.com/privacy-policy/" target="_blank" @click.stop>privacy policy &amp; terms</a>
                        </div>
                      </template>
                    </v-checkbox>
                    <p class="error--text">{{errorMessages.registerError}}</p>
                    <p class="success--text">{{errorMessages.registerSucces}}</p>
                    <v-btn block
                           color="primary"
                           type="submit"
                           class="mt-6">
                      Sign Up
                    </v-btn>
                  </v-form>
                </v-card-text>



                <v-card-text v-if="errorMessages.registerSucces">
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    We've sent an email to {{email.value}} to verify your email address and activate your account, the link in the email will expire in 24 hours.
                  </p>
                </v-card-text>
                <v-card-text v-if="errorMessages.registerSucces">
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    returning to login screen in {{countDown.timer}}
                  </p>
                </v-card-text>


                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    Already have an account?
                  </p>
                  <router-link :to="{name:'auth-login'}">
                    Sign in instead
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <!--<v-card-text class="d-flex align-center mt-2">
    <v-divider></v-divider>
    <span class="mx-5">or</span>
    <v-divider></v-divider>
  </v-card-text>-->
                <!-- social links -->
                <!--<v-card-actions class="d-flex justify-center">
    <v-btn
      v-for="link in socialLink"
      :key="link.icon"
      icon
      class="ms-1"
    >
      <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </v-card-actions>-->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { required, emailValidator, passwordValidator, alphaValidator } from '@core/utils/validation'
import { ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

  import { createUserWithEmailAndPassword, signOut, sendEmailVerification } from "firebase/auth"
import { db, auth} from '../Firebase/Firebase.service'
import { setDoc, doc , collection, serverTimestamp } from "firebase/firestore"

  export default {


  setup() {
    // Template Refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

      const countDown = ref({ timer: 15})

    const handleFormSubmit = async () => {

      const isFormValid = registerForm.value.validate()
      if (!isFormValid) return

      //create user
      createUserWithEmailAndPassword(auth, email.value, password.value).then((userCredential) => {
        //add user document
        setDoc(doc(db, "users", userCredential.user.uid), {
          avatarImage: "",
          dateCreated: serverTimestamp(),
          dateLastLogin: serverTimestamp(),
          email: email.value,
          token: "",
          username: username.value,
        }).then(() => {
          console.log("user doc created, Try send email verification");
          sendEmailVerification(auth.currentUser).then(() => {
            console.log("Try send email verification success");
            errorMessages.value = { registerSucces: true }
            signOut(auth).then(() => { countDownTimer() })
          }).catch((error) => {
            console.log("Try send email verification error " + error.code);
            errorMessages.value = { registerError: error.code }
          })
        }).catch((error) => {
          console.log("error creating document " + error.code);
          errorMessages.value = { registerError: error.code }
        })
      }).catch((error) => {

        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessages.value = { registerError: 'User already exists' }
          default:
        }
      })
    }
    
    const countDownTimer = () => {
      setTimeout(() => {

        countDown.value = { timer: countDown.value.timer -1}
        if (countDown.value.timer <= 0) {
          router.push('/')
        }
        else {
          countDownTimer()
        }
      }, 1000)
    }
    



    const handleFormSubmit2 = () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken (Register the user & login) (User will be added to the database and new generated access token will be sent for loggin in)
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      axios
        .post('/auth/register', { username: username.value, email: email.value, password: password.value })
        .then(response => {
          const { accessToken } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(() => {
          axios.get('/auth/me').then(response => {
            const { user } = response.data
            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // ? Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // ? We will store `userAbility` in localStorage separate from userData
            // ? Hence, we are just removing it from user object
            delete user.ability

            // ? Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // ? On success redirect to home
            router.push('/')
          })
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to Register!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    }

    return {
      countDown,
      countDownTimer,
      isPasswordVisible,
      username,
      email,
      password,
      errorMessages,
      handleFormSubmit,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
    }
    },
  computed: {
    getPhoto() {
      return this.images[Math.floor(Math.random() * this.images.length)]
    }
  },
  data: function () {
    return {
      images: [
        require('@/assets/epiito/images/Manufactoring.jpg'),
        require('@/assets/epiito/images/Epiito_Studio.jpg'),
        require('@/assets/epiito/images/HeadMountedDisplay.jpg'),
        require('@/assets/epiito/images/Lady_VR.jpg'),
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
